var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[(_vm.loadingCard)?_c('div',[_c('v-skeleton-loader',{attrs:{"type":"article, actions"}}),_c('v-skeleton-loader',{attrs:{"type":"table-heading, list-item-two-line, image, table-tfoot"}})],1):_c('div',[_c('v-card-title',[_c('span',{staticClass:"py-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',{staticClass:"pb-2"},[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.save()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"dense":"","label":"Nombre","outlined":"","rules":_vm.rules.required.concat(
                  [_vm.rules.maxLength(_vm.clienteNombre, 50)],
                  [_vm.rules.requiredTrim(_vm.clienteNombre)]
                )},model:{value:(_vm.clienteNombre),callback:function ($$v) {_vm.clienteNombre=$$v},expression:"clienteNombre"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"5"}},[_c('v-autocomplete',{attrs:{"items":_vm.equiposSoporte,"item-text":"value","item-value":"id","label":"Equipo de soporte","outlined":"","autocomplete":"off","clearable":"","dense":"","rules":!_vm.sinSoporte ? _vm.rules.required : []},model:{value:(_vm.equipoSoporteSelected),callback:function ($$v) {_vm.equipoSoporteSelected=$$v},expression:"equipoSoporteSelected"}})],1),_c('v-col',{staticClass:"py-0 px-0",attrs:{"cols":"2"}},[_c('v-subheader',{staticClass:"py-0 px-0"},[_c('v-switch',{staticClass:"ml-4",attrs:{"dense":"","outlined":""},model:{value:(_vm.sinSoporte),callback:function ($$v) {_vm.sinSoporte=$$v},expression:"sinSoporte"}}),_vm._v(" Sin soporte ")],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.provincias,"item-text":"value","item-value":"id","label":"Provincia","outlined":"","autocomplete":"off","clearable":"","dense":""},model:{value:(_vm.provinciaSelected),callback:function ($$v) {_vm.provinciaSelected=$$v},expression:"provinciaSelected"}})],1),_c('v-col',{staticClass:"py-0 px-0",attrs:{"cols":"2"}},[_c('v-subheader',{staticClass:"py-0 px-0"},[_c('v-switch',{staticClass:"ml-4",attrs:{"dense":"","outlined":""},on:{"change":function($event){(_vm.fechaLicencia = '2900-01-01'), (_vm.fechaLicenciaSelected = '01/01/2900')}},model:{value:(_vm.licenciaPerpetua),callback:function ($$v) {_vm.licenciaPerpetua=$$v},expression:"licenciaPerpetua"}}),_vm._v(" Licencia perpetua ")],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('v-menu',{ref:"menu-fecha-licencia",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha de licencia","append-icon":_vm.calendarIcon,"outlined":"","clearable":"","dense":"","disabled":_vm.licenciaPerpetua,"filled":_vm.licenciaPerpetua,"hint":"Formato DD/MM/AAAA","rules":!_vm.licenciaPerpetua
                      ? _vm.rules.required.concat(_vm.rules.validDate)
                      : []},on:{"blur":function($event){_vm.fechaLicencia = _vm.parseDateToIso(_vm.fechaLicenciaSelected)}},model:{value:(_vm.fechaLicenciaSelected),callback:function ($$v) {_vm.fechaLicenciaSelected=$$v},expression:"fechaLicenciaSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaLicencia),callback:function ($$v) {_vm.menuFechaLicencia=$$v},expression:"menuFechaLicencia"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaLicenciaSelected = _vm.formatDate(_vm.fechaLicencia)},"input":function($event){_vm.menuFechaLicencia = false}},model:{value:(_vm.fechaLicencia),callback:function ($$v) {_vm.fechaLicencia=$$v},expression:"fechaLicencia"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('v-menu',{ref:"menu-fecha-baja",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha de baja","append-icon":_vm.calendarIcon,"outlined":"","clearable":"","dense":"","hint":"Formato DD/MM/AAAA"},on:{"blur":function($event){_vm.fechaBaja = _vm.parseDateToIso(_vm.fechaBajaSelected)}},model:{value:(_vm.fechaBajaSelected),callback:function ($$v) {_vm.fechaBajaSelected=$$v},expression:"fechaBajaSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaBaja),callback:function ($$v) {_vm.menuFechaBaja=$$v},expression:"menuFechaBaja"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaBajaSelected = _vm.formatDate(_vm.fechaBaja)},"input":function($event){_vm.menuFechaBaja = false}},model:{value:(_vm.fechaBaja),callback:function ($$v) {_vm.fechaBaja=$$v},expression:"fechaBaja"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Observaciones","dense":"","auto-grow":"","rows":"1","outlined":""},model:{value:(_vm.observaciones),callback:function ($$v) {_vm.observaciones=$$v},expression:"observaciones"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":!_vm.isFormValid,"form":"form","loading":_vm.isLoading}},[_vm._v(" Guardar ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }