<template>
  <v-card outlined>
    <div v-if="loadingCard">
      <v-skeleton-loader type="article, actions"></v-skeleton-loader>

      <v-skeleton-loader
        type="table-heading, list-item-two-line, image, table-tfoot"
      ></v-skeleton-loader>
    </div>
    <div v-else>
      <v-card-title>
        <span class="py-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text class="pb-2">
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="5" class="py-0">
              <v-text-field
                v-model="clienteNombre"
                dense
                label="Nombre"
                outlined
                :rules="
                  rules.required.concat(
                    [rules.maxLength(clienteNombre, 50)],
                    [rules.requiredTrim(clienteNombre)]
                  )
                "
              >
              </v-text-field>
            </v-col>
            <v-col cols="5" class="py-0">
              <v-autocomplete
                v-model="equipoSoporteSelected"
                :items="equiposSoporte"
                item-text="value"
                item-value="id"
                label="Equipo de soporte"
                outlined
                autocomplete="off"
                clearable
                dense
                :rules="!sinSoporte ? rules.required : []"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2" class="py-0 px-0">
              <v-subheader class="py-0 px-0">
                <v-switch
                  class="ml-4"
                  v-model="sinSoporte"
                  dense
                  outlined
                ></v-switch>
                Sin soporte
              </v-subheader>
            </v-col>
            <v-col cols="4" class="py-0">
              <v-autocomplete
                v-model="provinciaSelected"
                :items="provincias"
                item-text="value"
                item-value="id"
                label="Provincia"
                outlined
                autocomplete="off"
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2" class="py-0 px-0">
              <v-subheader class="py-0 px-0">
                <v-switch
                  class="ml-4"
                  v-model="licenciaPerpetua"
                  dense
                  outlined
                  @change="
                    (fechaLicencia = '2900-01-01'), (fechaLicenciaSelected = '01/01/2900')
                  "
                ></v-switch>
                Licencia perpetua
              </v-subheader>
            </v-col>
            <v-col cols="3" class="py-0">
              <v-menu
                ref="menu-fecha-licencia"
                v-model="menuFechaLicencia"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaLicenciaSelected"
                    label="Fecha de licencia"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    outlined
                    clearable
                    dense
                    :disabled="licenciaPerpetua"
                    :filled="licenciaPerpetua"
                    hint="Formato DD/MM/AAAA"
                    v-mask="'##/##/####'"
                    @blur="
                      fechaLicencia = parseDateToIso(fechaLicenciaSelected)
                    "
                    v-on="on"
                    :rules="
                      !licenciaPerpetua
                        ? rules.required.concat(rules.validDate)
                        : []
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaLicencia"
                  no-title
                  @change="fechaLicenciaSelected = formatDate(fechaLicencia)"
                  @input="menuFechaLicencia = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3" class="py-0">
              <v-menu
                ref="menu-fecha-baja"
                v-model="menuFechaBaja"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaBajaSelected"
                    label="Fecha de baja"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    outlined
                    clearable
                    dense
                    hint="Formato DD/MM/AAAA"
                    v-mask="'##/##/####'"
                    @blur="fechaBaja = parseDateToIso(fechaBajaSelected)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaBaja"
                  no-title
                  @change="fechaBajaSelected = formatDate(fechaBaja)"
                  @input="menuFechaBaja = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-textarea
                v-model="observaciones"
                label="Observaciones"
                dense
                auto-grow
                rows="1"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          color="primary"
          type="submit"
          :disabled="!isFormValid"
          form="form"
          :loading="isLoading"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";

export default {
  name: "EditCliente",
  directives: { mask },
  props: {
    clienteId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      rules: rules,
      formEditTitle: "Nuevo cliente",
      isFormValid: false,
      clienteNombre: null,
      observaciones: null,
      equiposSoporte: [],
      equipoSoporteSelected: null,
      provincias: [],
      provinciaSelected: null,
      sinSoporte: false,
      licenciaPerpetua: false,
      calendarIcon: "mdi-calendar",
      fechaLicencia: null,
      fechaLicenciaSelected: null,
      menuFechaLicencia: false,
      menuFechaBaja: false,
      fechaBaja: null,
      fechaBajaSelected: null,
      editClient: false,
      itemClient: null,
      isLoading: false,
      loadingCard: false,
    };
  },
  created() {
    this.setSelects();
    if (this.clienteId != null) this.setClienteToEdit();
  },
  methods: {
    ...mapActions({
      getEquipos: "clientesData/equiposTaxonomy",
      getProvincias: "clientesData/provinciasTaxonomy",
      getClienteById: "clientesData/getClienteById",
      saveCliente: "clientesData/saveCliente",
      setAlert: "user/setAlert",
    }),
    async setSelects() {
      this.equiposSoporte = await this.getEquipos();
      this.provincias = await this.getProvincias();
    },
    async setClienteToEdit() {
      this.formEditTitle = "Editar cliente";
      this.loadingCard = true;
      try {
        const response = await this.getClienteById(this.clienteId);
        if (response.status === 200) {
          this.clienteNombre = response.data.data.clienteNombre;
          this.observaciones = response.data.data.observaciones;
          this.licenciaPerpetua = response.data.data.licenciaPerpetua;
          this.sinSoporte = response.data.data.sinSoporte;
          this.equipoSoporteSelected = response.data.data.equipoId;
          this.provinciaSelected = response.data.data.provId;
          this.fechaLicencia = response.data.data.fechaLicencia;
          this.fechaLicenciaSelected = this.formatDate(
            this.fechaLicencia.substring(0, 10)
          );
          if (response.data.data.fechaBaja != null) {
            this.fechaBaja = response.data.data.fechaBaja;
            this.fechaBajaSelected = this.formatDate(
              this.fechaBaja.substring(0, 10)
            );
          }
        }
      } catch (error) {
        console.error(error);
      }
      this.loadingCard = false;
    },
    async save() {
      this.isLoading = true;
      const data = {
        clienteId: this.clienteId != null ? this.clienteId : null,
        clienteNombre: this.clienteNombre,
        observaciones: this.observaciones,
        licenciaPerpetua: this.licenciaPerpetua,
        fechaLicencia: this.fechaLicencia,
        equipoId: this.equipoSoporteSelected,
        provId: this.provinciaSelected,
        fechaBaja: this.fechaBaja,
        sinSoporte: this.sinSoporte,
      };
      try {
        const response = await this.saveCliente(data);
        if (response.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.$emit("closeAndReload", true);
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
    closeModal() {
      this.$emit("closeAndReload", false);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${month}/${day}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
  },
};
</script>

<style></style>
