<template>
  <v-container>
    <go-back-btn :routeToGo="routeToGo" />
    <PageHeader :title="title" class="mb-3" />
    <v-row>
      <!-- Filtros -->
      <v-col cols="12">
        <v-card flat class="mx-auto my-12 mt-2 mb-2" outlined>
          <v-container>
            <v-form
              v-model="isFormValid"
              ref="clientes-filters-form"
              id="clientes-filters-form"
              @submit.prevent="applyFilters()"
            >
              <v-row class="mr-1">
                <!-- Nombre de cliente -->
                <v-col cols="3" class="pb-0">
                  <!-- Alinea a la izquierda -->
                  <v-text-field
                    v-model="clienteNombre"
                    label="Cliente"
                    single-line
                    :rules="[rules.maxLength(clienteNombre, 100)]"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="3" class="pb-0">
                  <v-autocomplete
                    v-model="provinciaSelected"
                    :items="provincias"
                    item-text="value"
                    item-value="id"
                    label="Provincia"
                    outlined
                    autocomplete="off"
                    clearable
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="3" class="pb-0 px-0">
                  <v-subheader class="py-0 px-0">
                    <v-switch
                      class="ml-4"
                      v-model="soloClientesActivos"
                      dense
                      outlined
                    ></v-switch>
                    Solo clientes activos al:
                    {{ new Date(Date.now()).toLocaleString().split(",")[0] }}
                  </v-subheader>
                </v-col>
                <v-col cols="3" class="pb-0 px-0">
                  <v-subheader class="py-0 px-0">
                    <v-switch
                      class="ml-4"
                      v-model="soloClientesSinSoporte"
                      dense
                      outlined
                    ></v-switch>
                    Solo clientes sin soporte
                  </v-subheader>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <!-- Alinea a la derecha -->
                  <v-btn
                    color="primary"
                    :disabled="!isFormValid"
                    small
                    class="to-right mt-1"
                    :dark="isFormValid"
                    type="submit"
                    form="clientes-filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <!-- Data table -->
    <v-card>
      <v-data-table
        class="elevation-2"
        :headers="clientesHeaders"
        :items="registrosClientes"
        :loading="loading"
        no-data-text="No hay datos disponibles para mostrar"
        :search="searchConsulta"
        item-key="clienteId"
      >
        <template v-slot:[`item.soporte`]="{ item }">
          <v-icon
            v-if="item.soporte"
            small
            color="primary"
            class="d-flex justify-center"
          >
            {{ checkIcon }}
          </v-icon>
          <v-icon v-else small color="red" class="d-flex justify-center">{{
            crossIcon
          }}</v-icon>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="10" align-self="center" class="pt-5">
                <v-text-field
                  v-model="searchConsulta"
                  clearable
                  label="Buscar"
                  single-line
                  :append-icon="iconoSearch"
                ></v-text-field>
              </v-col>
              <v-col cols="2" align="end" class="pt-5" v-if="canCreate">
                <v-btn color="primary" class="to-right" @click="openModal()">
                  Nuevo
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top v-if="canAccesoBasicos">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                size="20"
                @click="goToSeeAllDataClient(item)"
              >
                {{ seeIcon }}
              </v-icon>
            </template>
            <span>Ver todo</span>
          </v-tooltip>
          <v-tooltip top v-if="canGestionarContactos">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                size="20"
                @click="goContactosClientes(item)"
              >
                {{ iconContacts }}
              </v-icon>
            </template>
            <span>Contactos por cliente</span>
          </v-tooltip>
          <v-tooltip top v-if="canAccesoBasicos">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                size="20"
                @click="goSitios(item)"
              >
                {{ icon_setting2 }}
              </v-icon>
            </template>
            <span>Sitios por clientes</span>
          </v-tooltip>
          <v-tooltip top v-if="canAccesoBasicos">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                size="20"
                @click="goServidores(item)"
              >
                {{ icon_setting }}
              </v-icon>
            </template>
            <span>Servidores por clientes</span>
          </v-tooltip>
          <v-tooltip top v-if="canAccesoBasicos">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                size="20"
                @click="goUserBasicos(item)"
              >
                {{ user }}
              </v-icon>
            </template>
            <span>Usuarios básicos</span>
          </v-tooltip>
          <v-tooltip left top v-if="canAccesoAvanzados">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="goUserAvanzado(item)"
              >
                {{ iconoAdvanced }}
              </v-icon>
            </template>
            <span>Usuarios avanzados</span>
          </v-tooltip>
          <v-tooltip left top v-if="canAccesoDatos">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="goDatosAcceso(item)"
              >
                {{ data_acces }}
              </v-icon>
            </template>
            <span>Datos de acceso</span>
          </v-tooltip>
          <v-tooltip left top v-if="canConexionRdp">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="goConexionRdp(item)"
              >
                {{ icon_conect }}
              </v-icon>
            </template>
            <span>Conexiones RDP</span>
          </v-tooltip>
          <v-tooltip left v-if="canEdit">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="openModal(item.clienteId)"
              >
                {{ editIcon }}
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip left v-if="canDelete">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="openModalDeleteCliente(item)"
              >
                {{ deleteIcon }}
              </v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="70%"
      persistent
      @keydown.esc="closeAndReload(false)"
    >
      <EditCliente
        :clienteId="clienteId"
        @closeAndReload="closeAndReload($event)"
      ></EditCliente>
    </v-dialog>
    <DeleteDialog
      max-width="30%"
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      :isLoading="isLoadingDelete"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader.vue";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import enums from "@/utils/enums/index.js";
import enumsAdmin from "@/utils/enums/modules/index.js";
import GoBackBtn from "@/components/shared/GoBackBtn";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditCliente from "./EditCliente.vue";

export default {
  directives: { mask },
  components: { PageHeader, GoBackBtn, EditCliente, DeleteDialog },
  name: "Clientes",
  data() {
    return {
      title: "Clientes",
      titleDelete: "¿Eliminar cliente?",
      showDeleteModal: false,
      idToDelete: null,
      isLoadingDelete: false,
      isAvanzado: false,
      isFormValid: false,
      chevronUpIcon: enums.icons.CHEVRON_UP,
      chevronDownIcon: enums.icons.CHEVRON_DOWN,
      historic: enums.icons.HISTORIC,
      loading: false,
      seeIcon: enums.icons.SEE,
      filtrosGuardados: {},
      routeToGo: "/",
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      iconoAdvanced: enums.icons.USER_CORBATA,
      iconContacts: enums.icons.CONTACTS,
      checkIcon: enums.icons.CHECK_OUTLINE,
      crossIcon: enums.icons.CROSS_OUTLINE,
      user: enums.icons.USER,
      rules: rules,
      menu1: false,
      menu2: false,
      userAvanzados: false,
      fechaDesde: null,
      fechaDesdeSelected: null,
      fechaHasta: null,
      fechaHastaSelected: null,
      tipoConsulta: null,
      clienteNombre: null,
      singleExpand: false,
      expanded: [],
      searchConsulta: null,
      iconoSearch: enums.icons.SEARCH_ICON,
      iconoExcel: enums.icons.EXCEL_ICON,
      data_acces: enums.icons.ACCES_DATA,
      icon_conect: enums.icons.ICON_CONNECTION,
      icon_setting: enums.icons.SETTING,
      icon_setting2: enums.icons.SETTING2,

      clientesHeaders: [
        {
          text: "Id cliente",
          align: "start",
          sortable: true,
          value: "clienteId",
        },
        { text: "Nombre de cliente", sortable: true, value: "clienteNombre" },
        {
          text: "Código equipo",
          align: "center",
          sortable: true,
          value: "equipoCod",
        },
        {
          text: "Equipo",
          align: "start",
          sortable: false,
          value: "equipo",
        },
        {
          text: "Fecha de baja",
          align: "start",
          sortable: true,
          value: "fechaBaja",
        },
        {
          text: "Soporte",
          align: "center",
          sortable: true,
          value: "soporte",
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
      ],
      registrosClientes: [],
      canAccesoBasicos: false,
      canAccesoAvanzados: false,
      canAccesoDatos: false,
      canConexionRdp: false,
      canCreate: false,
      canEdit: false,
      canDelete: false,
      canGestionarContactos: false,
      allowedActions: null,
      optionCodeUsuBasic: enumsAdmin.adminSistema.permissions.USUARIOS_BASICOS,
      optionCodeUsuAvan: enumsAdmin.adminSistema.permissions.USUARIOS_AVANZADOS,
      optionCodeDatosAcceso: enumsAdmin.adminSistema.permissions.DATOS_ACCESO,
      optionCodeConexionRdp: enumsAdmin.adminSistema.permissions.CONEXIONES_RDP,
      openModalEdit: false,
      clienteId: null,
      provincias: [],
      provinciaSelected: null,
      soloClientesActivos: true,
      soloClientesSinSoporte: false,
    };
  },
  async created() {
    this.setSelects();
    this.setPermisos();
    if (this.$store.state.filtersClientes == null) this.applyFilters();
  },
  async mounted() {
    if (this.$store.state.filtersClientes != null) {
      this.clienteNombre = this.$store.state.filtersClientes.clienteNombre;
      this.provinciaSelected = this.$store.state.filtersClientes.provId;
      this.soloClientesActivos =
        this.$store.state.filtersClientes.clientesActivosFechaHoy;
      this.soloClientesSinSoporte =
        this.$store.state.filtersClientes.soloClientesSinSoporte;
      this.applyFilters();
    }
    this.$store.state.filtersClientes = null;
  },
  methods: {
    ...mapActions({
      getProvincias: "clientesData/provinciasTaxonomy",
      fetchUsuariosPorCliente: "clientesData/fetchUsuariosPorCliente",
      deleteCliente: "clientesData/deleteCliente",
      setAlert: "user/setAlert",
    }),
    async setSelects() {
      this.provincias = await this.getProvincias();
    },
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enumsAdmin.adminSistema.permissions.USUARIOS_BASICOS:
            this.canAccesoBasicos = true;
            break;
          case enumsAdmin.adminSistema.permissions.USUARIOS_AVANZADOS:
            this.canAccesoAvanzados = true;
            break;
          case enumsAdmin.adminSistema.permissions.DATOS_ACCESO:
            this.canAccesoDatos = true;
            break;
          case enumsAdmin.adminSistema.permissions.CONEXIONES_RDP:
            this.canConexionRdp = true;
            break;
          case enumsAdmin.adminSistema.permissions.NUEVO_CLIENTE:
            this.canCreate = true;
            break;
          case enumsAdmin.adminSistema.permissions.EDITAR_CLIENTE:
            this.canEdit = true;
            break;
          case enumsAdmin.adminSistema.permissions.ELIMINAR_CLIENTE:
            this.canDelete = true;
            break;
          case enumsAdmin.adminSistema.permissions.GESTIONAR_CONTACTOS:
            this.canGestionarContactos = true;
            break;
          default:
            break;
        }
      });
    },
    goContactosClientes(item) {
      this.$router.push({
        name: "ContactosClientes",
        params: {
          itemsClientes: item,
        },
      });
      this.$store.state.filtersClientes = this.filtrosGuardados;
    },
    goSitios(item) {
      this.$router.push({
        name: "SitiosSistemas",
        params: {
          itemsClientes: item,
        },
      });
      this.$store.state.filtersClientes = this.filtrosGuardados;
    },

    goServidores(item) {
      this.$router.push({
        name: "ServidoresSistemas",
        params: {
          itemsClientes: item,
        },
      });
      this.$store.state.filtersClientes = this.filtrosGuardados;
    },
    goUserBasicos(item) {
      this.userAvanzados = false;
      this.$router.push({
        name: "UsersBasicsYAvan",
        params: {
          itemsClientes: item,
          userAvanzados: this.userAvanzados,
          parentOptionCode: this.optionCodeUsuBasic,
        },
      });
      this.$store.state.filtersClientes = this.filtrosGuardados;
    },
    goToSeeAllDataClient(item) {
      this.userAvanzados = false;
      this.$router.push({
        name: "SeeAllDataClient",
        params: {
          clientItem: item,
          parentOptionCode: this.optionCodeUsuBasic,
        },
      });
      this.$store.state.filtersClientes = this.filtrosGuardados;
    },
    goUserAvanzado(item) {
      this.userAvanzados = true;
      this.$router.push({
        name: "UsersBasicsYAvan",
        params: {
          itemsClientes: item,
          userAvanzados: this.userAvanzados,
          parentOptionCode: this.optionCodeUsuAvan,
        },
      });
      this.$store.state.filtersClientes = this.filtrosGuardados;
    },
    goDatosAcceso(item) {
      this.$router.push({
        name: "AccesoDatos",
        params: {
          itemsClientes: item,
        },
      });
      this.$store.state.filtersClientes = this.filtrosGuardados;
    },
    goConexionRdp(item) {
      this.$router.push({
        name: "ConexionRdp",
        params: {
          itemsClientes: item,
        },
      });
      this.$store.state.filtersClientes = this.filtrosGuardados;
    },
    closeModalUser() {
      this.openModalUsers = false;
    },
    async applyFilters() {
      this.$store.state.filtersClientes == null;
      this.loading = true;
      const data = {
        clienteNombre: this.clienteNombre != null ? this.clienteNombre : "",
        provId: this.provinciaSelected,
        clientesActivosFechaHoy: this.soloClientesActivos,
        soloClientesSinSoporte: this.soloClientesSinSoporte,
      };
      this.filtrosGuardados = {
        clienteNombre: this.clienteNombre != null ? this.clienteNombre : "",
        provId: this.provinciaSelected,
        clientesActivosFechaHoy: this.soloClientesActivos,
        soloClientesSinSoporte: this.soloClientesSinSoporte,
      };
      const response = await this.fetchUsuariosPorCliente(data);
      this.registrosClientes = response;
      this.loading = false;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    openModalDeleteCliente(item) {
      this.showDeleteModal = true;
      this.idToDelete = item.clienteId;
    },
    async confirmDelete() {
      this.isLoadingDelete = true;
      try {
        const response = await this.deleteCliente(this.idToDelete);
        if (response.status === 200) {
          this.showDeleteModal = false;
          if (response.data.data.idError === 0) {
            this.setAlert({ type: "success", message: "Eliminado con éxito." });
            this.applyFilters();
          } else {
            this.setAlert({
              type: "warning",
              message: `El cliente no ha podido ser eliminado ya que posee datos de ${response.data.data.descripcion} asignados`,
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoadingDelete = false;
    },
    openModal(id) {
      this.clienteId = id;
      this.openModalEdit = true;
    },
    closeAndReload(event) {
      this.openModalEdit = false;
      if (event) this.applyFilters();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .myLabel {
  color: white;
}
.to-right {
  float: right;
}
ul {
  list-style: none;
}
</style>
